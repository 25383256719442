@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'antilles';
    src: url('#{$icomoon-font-path}/antilles.eot?hduekv');
    src: url('#{$icomoon-font-path}/antilles.eot?hduekv#iefix') format('embedded-opentype'), url('#{$icomoon-font-path}/antilles.ttf?hduekv') format('truetype'), url('#{$icomoon-font-path}/antilles.woff?hduekv') format('woff'), url('#{$icomoon-font-path}/antilles.svg?hduekv#antilles') format('svg');
}

.afp-2x {
    font-size: 2rem;
}

.afp-3x {
    font-size: 3rem;
}

.afp-4x {
    font-size: 4rem;
}

.afp-5x {
    font-size: 5rem;
}

.afp {
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;

/* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'antilles' !important;
    line-height: 1;

    speak: none;

/* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
    @return unquote('"') + $str + unquote('"');
}

.afp-data-templates {
    &:before {
        content: unicode($afp-data-templates);
    }
}

.afp-lrs {
    &:before {
        content: unicode($afp-lrs);
    }
}

.afp-site-improve {
    &:before {
        content: unicode($afp-site-improve);
    }
}

.afp-archive {
    &:before {
        content: unicode($afp-archive);
    }
}

.afp-coordinates {
    &:before {
        content: unicode($afp-coordinates);
    }
}

.afp-git {
    &:before {
        content: unicode($afp-git);
    }
}

.afp-payment {
    &:before {
        content: unicode($afp-payment);
    }
}

.afp-phone {
    &:before {
        content: unicode($afp-phone);
    }
}

.afp-redirect {
    &:before {
        content: unicode($afp-redirect);
    }
}

.afp-has-comments {
    &:before {
        content: unicode($afp-has-comments);
    }
}

.afp-no-comments {
    &:before {
        content: unicode($afp-no-comments);
    }
}

.afp-click-dimensions {
    &:before {
        content: unicode($afp-click-dimensions);
    }
}

.afp-bulk-upload {
    &:before {
        content: unicode($afp-bulk-upload);
    }
}

.afp-tags {
    &:before {
        content: unicode($afp-tags);
    }
}

.afp-upload {
    &:before {
        content: unicode($afp-upload);
    }
}

.afp-alert {
    &:before {
        content: unicode($afp-alert);
    }
}

.afp-audit {
    &:before {
        content: unicode($afp-audit);
    }
}

.afp-ban {
    &:before {
        content: unicode($afp-ban);
    }
}

.afp-blog {
    &:before {
        content: unicode($afp-blog);
    }
}

.afp-bullhorn {
    &:before {
        content: unicode($afp-bullhorn);
    }
}

.afp-calculator {
    &:before {
        content: unicode($afp-calculator);
    }
}

.afp-camera {
    &:before {
        content: unicode($afp-camera);
    }
}

.afp-cart {
    &:before {
        content: unicode($afp-cart);
    }
}

.afp-chat {
    &:before {
        content: unicode($afp-chat);
    }
}

.afp-checkmark {
    &:before {
        content: unicode($afp-checkmark);
    }
}

.afp-clock {
    &:before {
        content: unicode($afp-clock);
    }
}

.afp-close {
    &:before {
        content: unicode($afp-close);
    }
}

.afp-cloud {
    &:before {
        content: unicode($afp-cloud);
    }
}

.afp-company-directory {
    &:before {
        content: unicode($afp-company-directory);
    }
}

.afp-dashboard {
    &:before {
        content: unicode($afp-dashboard);
    }
}

.afp-down-arrow {
    &:before {
        content: unicode($afp-down-arrow);
    }
}

.afp-download {
    &:before {
        content: unicode($afp-download);
    }
}

.afp-employment {
    &:before {
        content: unicode($afp-employment);
    }
}

.afp-envelope {
    &:before {
        content: unicode($afp-envelope);
    }
}

.afp-events {
    &:before {
        content: unicode($afp-events);
    }
}

.afp-exclamation-point {
    &:before {
        content: unicode($afp-exclamation-point);
    }
}

.afp-expand {
    &:before {
        content: unicode($afp-expand);
    }
}

.afp-eye {
    &:before {
        content: unicode($afp-eye);
    }
}

.afp-eye-covered {
    &:before {
        content: unicode($afp-eye-covered);
    }
}

.afp-fast-backward {
    &:before {
        content: unicode($afp-fast-backward);
    }
}

.afp-fast-backward-beginning {
    &:before {
        content: unicode($afp-fast-backward-beginning);
    }
}

.afp-fast-forward {
    &:before {
        content: unicode($afp-fast-forward);
    }
}

.afp-fast-forward-end {
    &:before {
        content: unicode($afp-fast-forward-end);
    }
}

.afp-forms {
    &:before {
        content: unicode($afp-forms);
    }
}

.afp-forward {
    &:before {
        content: unicode($afp-forward);
    }
}

.afp-heart {
    &:before {
        content: unicode($afp-heart);
    }
}

.afp-image {
    &:before {
        content: unicode($afp-image);
    }
}

.afp-information {
    &:before {
        content: unicode($afp-information);
    }
}

.afp-left-arrow {
    &:before {
        content: unicode($afp-left-arrow);
    }
}

.afp-lightbulb {
    &:before {
        content: unicode($afp-lightbulb);
    }
}

.afp-link {
    &:before {
        content: unicode($afp-link);
    }
}

.afp-lists {
    &:before {
        content: unicode($afp-lists);
    }
}

.afp-locations {
    &:before {
        content: unicode($afp-locations);
    }
}

.afp-lock {
    &:before {
        content: unicode($afp-lock);
    }
}

.afp-menu {
    &:before {
        content: unicode($afp-menu);
    }
}

.afp-message {
    &:before {
        content: unicode($afp-message);
    }
}

.afp-minimize {
    &:before {
        content: unicode($afp-minimize);
    }
}

.afp-mute {
    &:before {
        content: unicode($afp-mute);
    }
}

.afp-news {
    &:before {
        content: unicode($afp-news);
    }
}

.afp-new-window {
    &:before {
        content: unicode($afp-new-window);
    }
}

.afp-pages {
    &:before {
        content: unicode($afp-pages);
    }
}

.afp-paintbrush {
    &:before {
        content: unicode($afp-paintbrush);
    }
}

.afp-paperclip {
    &:before {
        content: unicode($afp-paperclip);
    }
}

.afp-pause {
    &:before {
        content: unicode($afp-pause);
    }
}

.afp-percent {
    &:before {
        content: unicode($afp-percent);
    }
}

.afp-play-button {
    &:before {
        content: unicode($afp-play-button);
    }
}

.afp-question-mark {
    &:before {
        content: unicode($afp-question-mark);
    }
}

.afp-refresh {
    &:before {
        content: unicode($afp-refresh);
    }
}

.afp-resources {
    &:before {
        content: unicode($afp-resources);
    }
}

.afp-rewind {
    &:before {
        content: unicode($afp-rewind);
    }
}

.afp-right-arrow {
    &:before {
        content: unicode($afp-right-arrow);
    }
}

.afp-rss {
    &:before {
        content: unicode($afp-rss);
    }
}

.afp-search {
    &:before {
        content: unicode($afp-search);
    }
}

.afp-seo {
    &:before {
        content: unicode($afp-seo);
    }
}

.afp-settings {
    &:before {
        content: unicode($afp-settings);
    }
}

.afp-star {
    &:before {
        content: unicode($afp-star);
    }
}

.afp-star-circle {
    &:before {
        content: unicode($afp-star-circle);
    }
}

.afp-stop {
    &:before {
        content: unicode($afp-stop);
    }
}

.afp-training {
    &:before {
        content: unicode($afp-training);
    }
}

.afp-trash {
    &:before {
        content: unicode($afp-trash);
    }
}

.afp-unlock {
    &:before {
        content: unicode($afp-unlock);
    }
}

.afp-up-arrow {
    &:before {
        content: unicode($afp-up-arrow);
    }
}

.afp-users {
    &:before {
        content: unicode($afp-users);
    }
}

.afp-vertical-dots {
    &:before {
        content: unicode($afp-vertical-dots);
    }
}

.afp-view-site {
    &:before {
        content: unicode($afp-view-site);
    }
}

.afp-volume {
    &:before {
        content: unicode($afp-volume);
    }
}

.afp-volume-down {
    &:before {
        content: unicode($afp-volume-down);
    }
}

.afp-wand {
    &:before {
        content: unicode($afp-wand);
    }
}

.afp-wifi {
    &:before {
        content: unicode($afp-wifi);
    }
}

.afp-zoom-in {
    &:before {
        content: unicode($afp-zoom-in);
    }
}

.afp-zoom-out {
    &:before {
        content: unicode($afp-zoom-out);
    }
}

.afp-minus {
    &:before {
        content: unicode($afp-minus);
    }
}

.afp-plus {
    &:before {
        content: unicode($afp-plus);
    }
}

.afp-sdk-developer {
    &:before {
        content: unicode($afp-sdk-developer);
    }
}

.afp-helpdesk {
    &:before {
        content: unicode($afp-helpdesk);
    }
}

.afp-save-and-return {
    &:before {
        content: unicode($afp-save-and-return);
    }
}

.afp-icon-font {
    &:before {
        content: unicode($afp-icon-font);
    }
}

.afp-clipboard {
    &:before {
        content: unicode($afp-clipboard);
    }
}

.afp-linked-in {
    &:before {
        content: unicode($afp-linked-in);
    }
}

.afp-google-plus {
    &:before {
        content: unicode($afp-google-plus);
    }
}

.afp-instagram {
    &:before {
        content: unicode($afp-instagram);
    }
}

.afp-youtube {
    &:before {
        content: unicode($afp-youtube);
    }
}

.afp-twitter {
    &:before {
        content: unicode($afp-twitter);
    }
}

.afp-pinterest {
    &:before {
        content: unicode($afp-pinterest);
    }
}

.afp-facebook {
    &:before {
        content: unicode($afp-facebook);
    }
}

.afp-integrations {
    &:before {
        content: unicode($afp-integrations);
    }
}

.afp-pencil {
    &:before {
        content: unicode($afp-pencil);
    }
}

.afp-drag-to-sort {
    &:before {
        content: unicode($afp-drag-to-sort);
    }
}

.afp-version {
    &:before {
        content: unicode($afp-version);
    }
}

.afp-quick-edit {
    &:before {
        content: unicode($afp-quick-edit);
    }
}

.afp-save {
    &:before {
        content: unicode($afp-save);
    }
}

.afp-floppy {
    &:before {
        content: unicode($afp-floppy);
    }
}

.afp-control-center {
    &:before {
        content: unicode($afp-control-center);
    }
}

.afp-edit {
    &:before {
        content: unicode($afp-edit);
    }
}

.afp-rates-solid-background {
    &:before {
        content: unicode($afp-rates-solid-background);
    }
}

.afp-rates {
    &:before {
        content: unicode($afp-rates);
    }
}

.afp-icons {
    &:before {
        content: unicode($afp-icons);
    }
}

.afp-modules {
    &:before {
        content: unicode($afp-modules);
    }
}

.afp-photo-gallery {
    &:before {
        content: unicode($afp-photo-gallery);
    }
}

.afp-duplicate {
    &:before {
        content: unicode($afp-duplicate);
    }
}

