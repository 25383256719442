.login {

  input[type='submit'] {
    display: block;
    width: 100%;
    height: 40px;
    margin: 0;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 18px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    white-space: no-wrap;
    vertical-align: baseline;
    cursor: pointer;
    background: #00a4b3;
    border: 1px solid #00a4b3;
    box-sizing: border-box;
    transition: all 0.4s ease;

    &:hover {
      background: #00838f;
      border: 1px solid #00838f;
    }
  }
}
