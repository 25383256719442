.logo {
    display: block;
    padding: 20px;

    @include respond-to($short) {
        padding: 40px;
    }

    @include clearfix;

    h1 {
        display: block;
        width: 250px;
        max-width: 250px;
        height: 130px;
        max-height: 130px;
        background: url('/antilles/assets/dist/images/lrswebsolutions-antilles-logo-fullname.svg') no-repeat center center;
        margin: 0 auto;

        @include respond-to($short) {
            width: 320px;
            max-width: 320px;
            height: 167px;
            max-height: 167px;
        }
    }

    @media (prefers-color-scheme: dark) {
        h1 {
            background: url('/antilles/assets/dist/images/lrswebsolutions-antilles-logo-white-fullname.svg') no-repeat center center;
        }
    }
}
