.login {
    label {
        display: block;
        margin: 0;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease;
        color: var(--txt-color);

        @include respond-to($short) {
            font-size: 16px;
        }
    }

    input[type='email'],
    input[type='password'],
    input[type='text'] {
        display: block;
        width: 100%;
        height: 40px;
        padding: 7px 8px;
        margin: 0;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 18px;
        font-weight: 100;
        line-height: 1;
        color: #555;
        border: 1px solid #ccc;
        outline: none;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
        box-sizing: border-box;
        transition: all 0.2s ease;

        @include placeholder {
            font-weight: 100;
            color: #aaa;
        }

        @include respond-to($short) {
            height: 54px;
            font-size: 24px;
        }
    }

    input[type='email']:focus,
    input[type='password']:focus,
    input[type='text']:focus {
        border-color: #999 !important;
        box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
    }

    input[type='checkbox'] {
        position: relative;
        float: left;
        width: 16px;
        height: 16px;
        max-height: auto;
        margin: 0;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
        background: white;
        appearance: none;
        transition: all 0.2s ease;

        &:checked {
            border-color: #999 !important;
            box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
            background: white;
            appearance: none;

            &:before {
                position: absolute;
                top: 2px;
                left: 2px;
                width: 10px;
                height: 10px;
                background: #999;
                content: '';
                // content: '\f00c';
                // text-decoration: inherit;
                // color: darkgray;
                // font-family: FontAwesome;
                // font-size: 18px;
                // font-weight: normal;
                // font-style: normal;
            }
        }

        &:focus {
            border-color: #999 !important;
            box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
            background: white;
            outline: none;
            appearance: none;
        }

        + label {
            position: relative;
            top: 0;
            display: inline-block;
            width: calc(100% - 20px);
            margin: 0;
            padding-left: 10px;
        }
    }

    .form-group {
        display: block;
        margin-bottom: 16px;
    }

    .show-pass {
        margin-top: 10px;

        input[type=checkbox] + label {
            top: -1px;
        }
    }

    a {
        color: var(--txt-color);
        text-decoration: none;
        transition: all 0.2s ease;

        &:hover {
            color: #428bca;
        }
    }
}
