.card {
    max-width: 300px;

    @include respond-to($short) {
        max-width: 480px;
    }

    padding: 32px 26px 24px;
    margin: 20px auto;
    overflow: hidden;
    font-weight: 400;
    border: 5px solid #e9e9e9;
    --bkg-color: #ffffff;

    @media (prefers-color-scheme: dark) {
        --bkg-color: #484B4E;
    }

    background: var(--bkg-color);

    h2 {
        margin-top: 0;
    }

    p {
        margin-bottom: 20px;
    }
}
