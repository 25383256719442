*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
    --bkg-color: #f9f9f9;
    --txt-color: #444444;
}

@media (prefers-color-scheme: dark) {
    body {
        --bkg-color: #303233;
        --txt-color: #ecf0f5;
    }
}

body {
    background: var(--bkg-color);
    color: var(--txt-color);
    min-width: 320px;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 13px;
    line-height: 1.4em;
}

form {
  padding: 0;

  @include respond-to($grande) {
    padding: 40px;
  }
}

h1 {
  margin: 0;
}

.pull-right {
  float: right !important;
}

input {
  appearance: none;
}