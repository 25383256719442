.version {
    display: block;
    font-size: 12px;
    text-align: center;
    opacity: 0.6;

    @include respond-to($short) {
        font-size: 12px;
    }

    @include respond-to($grande) {
        bottom: 20px;
    }

    a {
        --txt-color: #777;
    }

    @media (prefers-color-scheme: dark) {
        a {
            --txt-color: #ecf0f5;
        }
    }

    a {
        color: var(--txt-color);
        text-decoration: none;
    }
}
