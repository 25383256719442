.login {

  .alert {
    max-width: 360px;
    margin: 20px auto;
    padding: 15px 24px;
    font-weight: 400;
    overflow: hidden;
    background: #fff;
    border-color: #dddddd;
    border-style: solid;
    border-width: 1px 1px 1px 5px;
  }

  .alert-success {
    color: #3c763d;
    background: #dff0d8;
    border-color: #d6e9c6;
  }

  .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }

  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  .alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }
}

.alertContainer {
  display: block;
  position: fixed;
  top: 0;
  z-index: 1700;
  left: 50%;
  transform: translateX(-50%);
  transition: top .5s ease-in-out;

  .alert {
    border-radius: 0;
    z-index: 999999;
    padding: 20px 30px;
    background: rgb(147, 38, 204);
    font-weight: 600;
    font-size: 16px;
    width: calc(90vw - 40px);

    @media (min-width: 991px) {
      width: calc(60vw - 40px);
    }

    margin-bottom: 0;

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &.danger {
      border-color: #d73925;
      background-color: #dd4b39 !important;
      color: #ffffff !important;
    }

    &.warning {
      border-color: #e08e0b;
      background-color: #f39c12 !important;
      color: #ffffff !important;
    }

    &.info {
      border-color: #00acd6;
      background-color: #00c0ef !important;
      color: #ffffff !important;
    }

    &.success {
      border-color: #008d4c;
      background-color: #00a65a !important;
      color: #ffffff !important;
    }

    .close {
      color: rgb(255, 255, 255);
      font-size: 20px;
    }

    span {
      display: inline-block;
      margin-right: 10px;
      color: rgba(255, 255, 255, 0.901961);
      text-decoration: none;
    }

    btn {
      margin-top: -5px;
      border: 0;
      background: rgb(255, 255, 255);
      box-shadow: none;
      color: rgb(243, 156, 18);
      font-weight: 600;
    }
  }
}
