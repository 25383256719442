@mixin respond-to($min: 0, $max: 0, $prop: 'width', $media: 'screen') {
  $query: '';

  @if $min != 0 and $max != 0 {
    $query: '(min-#{$prop}: #{$min}) and (max-#{$prop}: #{$max})';
  } @else if $min != 0 and $max == 0 {
    $query: '(min-#{$prop}: #{$min})';
  } @else if $min == 0 and $max != 0 {
    $query: '(max-#{$prop}: #{$max})';
  }

  @media only #{$media} and #{$query} {
    @content;
  };
}
