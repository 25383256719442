*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  --bkg-color: #f9f9f9;
  --txt-color: #444444;
}

@media (prefers-color-scheme: dark) {
  body {
    --bkg-color: #303233;
    --txt-color: #ecf0f5;
  }
}
body {
  background: var(--bkg-color);
  color: var(--txt-color);
  min-width: 320px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4em;
}

form {
  padding: 0;
}
@media only screen and (min-width: 840px) {
  form {
    padding: 40px;
  }
}

h1 {
  margin: 0;
}

.pull-right {
  float: right !important;
}

input {
  appearance: none;
}

.card {
  max-width: 300px;
  padding: 32px 26px 24px;
  margin: 20px auto;
  overflow: hidden;
  font-weight: 400;
  border: 5px solid #e9e9e9;
  --bkg-color: #ffffff;
  background: var(--bkg-color);
}
@media only screen and (min-width: 480px) {
  .card {
    max-width: 480px;
  }
}
@media (prefers-color-scheme: dark) {
  .card {
    --bkg-color: #484B4E;
  }
}
.card h2 {
  margin-top: 0;
}
.card p {
  margin-bottom: 20px;
}

.logo {
  display: block;
  padding: 20px;
}
@media only screen and (min-width: 480px) {
  .logo {
    padding: 40px;
  }
}
.logo::after {
  clear: both;
  content: "";
  display: table;
}
.logo h1 {
  display: block;
  width: 250px;
  max-width: 250px;
  height: 130px;
  max-height: 130px;
  background: url("/antilles/assets/dist/images/lrswebsolutions-antilles-logo-fullname.svg") no-repeat center center;
  margin: 0 auto;
}
@media only screen and (min-width: 480px) {
  .logo h1 {
    width: 320px;
    max-width: 320px;
    height: 167px;
    max-height: 167px;
  }
}
@media (prefers-color-scheme: dark) {
  .logo h1 {
    background: url("/antilles/assets/dist/images/lrswebsolutions-antilles-logo-white-fullname.svg") no-repeat center center;
  }
}

.login .alert {
  max-width: 360px;
  margin: 20px auto;
  padding: 15px 24px;
  font-weight: 400;
  overflow: hidden;
  background: #fff;
  border-color: #dddddd;
  border-style: solid;
  border-width: 1px 1px 1px 5px;
}
.login .alert-success {
  color: #3c763d;
  background: #dff0d8;
  border-color: #d6e9c6;
}
.login .alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.login .alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.login .alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alertContainer {
  display: block;
  position: fixed;
  top: 0;
  z-index: 1700;
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.5s ease-in-out;
}
.alertContainer .alert {
  border-radius: 0;
  z-index: 999999;
  padding: 20px 30px;
  background: #9326cc;
  font-weight: 600;
  font-size: 16px;
  width: calc(90vw - 40px);
  margin-bottom: 0;
}
@media (min-width: 991px) {
  .alertContainer .alert {
    width: calc(60vw - 40px);
  }
}
.alertContainer .alert:last-child {
  border-radius: 0 0 6px 6px;
}
.alertContainer .alert.danger {
  border-color: #d73925;
  background-color: #dd4b39 !important;
  color: #ffffff !important;
}
.alertContainer .alert.warning {
  border-color: #e08e0b;
  background-color: #f39c12 !important;
  color: #ffffff !important;
}
.alertContainer .alert.info {
  border-color: #00acd6;
  background-color: #00c0ef !important;
  color: #ffffff !important;
}
.alertContainer .alert.success {
  border-color: #008d4c;
  background-color: #00a65a !important;
  color: #ffffff !important;
}
.alertContainer .alert .close {
  color: white;
  font-size: 20px;
}
.alertContainer .alert span {
  display: inline-block;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.901961);
  text-decoration: none;
}
.alertContainer .alert btn {
  margin-top: -5px;
  border: 0;
  background: white;
  box-shadow: none;
  color: #f39c12;
  font-weight: 600;
}

.login label {
  display: block;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--txt-color);
}
@media only screen and (min-width: 480px) {
  .login label {
    font-size: 16px;
  }
}
.login input[type=email],
.login input[type=password],
.login input[type=text] {
  display: block;
  width: 100%;
  height: 40px;
  padding: 7px 8px;
  margin: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-weight: 100;
  line-height: 1;
  color: #555;
  border: 1px solid #ccc;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-sizing: border-box;
  transition: all 0.2s ease;
}
.login input[type=email]::-webkit-input-placeholder,
.login input[type=password]::-webkit-input-placeholder,
.login input[type=text]::-webkit-input-placeholder {
  font-weight: 100;
  color: #aaa;
}
.login input[type=email]::-moz-placeholder,
.login input[type=password]::-moz-placeholder,
.login input[type=text]::-moz-placeholder {
  font-weight: 100;
  color: #aaa;
}
.login input[type=email]:-moz-placeholder,
.login input[type=password]:-moz-placeholder,
.login input[type=text]:-moz-placeholder {
  font-weight: 100;
  color: #aaa;
}
.login input[type=email]:-ms-input-placeholder,
.login input[type=password]:-ms-input-placeholder,
.login input[type=text]:-ms-input-placeholder {
  font-weight: 100;
  color: #aaa;
}
@media only screen and (min-width: 480px) {
  .login input[type=email],
.login input[type=password],
.login input[type=text] {
    height: 54px;
    font-size: 24px;
  }
}
.login input[type=email]:focus,
.login input[type=password]:focus,
.login input[type=text]:focus {
  border-color: #999 !important;
  box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
}
.login input[type=checkbox] {
  position: relative;
  float: left;
  width: 16px;
  height: 16px;
  max-height: auto;
  margin: 0;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  background: white;
  appearance: none;
  transition: all 0.2s ease;
}
.login input[type=checkbox]:checked {
  border-color: #999 !important;
  box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
  background: white;
  appearance: none;
}
.login input[type=checkbox]:checked:before {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: #999;
  content: "";
}
.login input[type=checkbox]:focus {
  border-color: #999 !important;
  box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
  background: white;
  outline: none;
  appearance: none;
}
.login input[type=checkbox] + label {
  position: relative;
  top: 0;
  display: inline-block;
  width: calc(100% - 20px);
  margin: 0;
  padding-left: 10px;
}
.login .form-group {
  display: block;
  margin-bottom: 16px;
}
.login .show-pass {
  margin-top: 10px;
}
.login .show-pass input[type=checkbox] + label {
  top: -1px;
}
.login a {
  color: var(--txt-color);
  text-decoration: none;
  transition: all 0.2s ease;
}
.login a:hover {
  color: #428bca;
}

.login input[type=submit] {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  white-space: no-wrap;
  vertical-align: baseline;
  cursor: pointer;
  background: #00a4b3;
  border: 1px solid #00a4b3;
  box-sizing: border-box;
  transition: all 0.4s ease;
}
.login input[type=submit]:hover {
  background: #00838f;
  border: 1px solid #00838f;
}

.sitename {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  opacity: 0.6;
}
@media only screen and (min-width: 480px) {
  .sitename {
    font-size: 16px;
  }
}
@media only screen and (min-width: 840px) {
  .sitename {
    bottom: 20px;
  }
}
.sitename a {
  --txt-color: #777;
}
@media (prefers-color-scheme: dark) {
  .sitename a {
    --txt-color: #ecf0f5;
  }
}
.sitename a {
  color: var(--txt-color);
  text-decoration: none;
}

.version {
  display: block;
  font-size: 12px;
  text-align: center;
  opacity: 0.6;
}
@media only screen and (min-width: 480px) {
  .version {
    font-size: 12px;
  }
}
@media only screen and (min-width: 840px) {
  .version {
    bottom: 20px;
  }
}
.version a {
  --txt-color: #777;
}
@media (prefers-color-scheme: dark) {
  .version a {
    --txt-color: #ecf0f5;
  }
}
.version a {
  color: var(--txt-color);
  text-decoration: none;
}

.qr-code {
  border: 1px solid #d9d9d9;
  margin: 0 auto 40px;
}
.qr-code img {
  display: block;
  width: 100%;
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: "antilles";
  src: url("/antilles/assets/dist/fonts/antilles.eot?hduekv");
  src: url("/antilles/assets/dist/fonts/antilles.eot?hduekv#iefix") format("embedded-opentype"), url("/antilles/assets/dist/fonts/antilles.ttf?hduekv") format("truetype"), url("/antilles/assets/dist/fonts/antilles.woff?hduekv") format("woff"), url("/antilles/assets/dist/fonts/antilles.svg?hduekv#antilles") format("svg");
}
.afp-2x {
  font-size: 2rem;
}

.afp-3x {
  font-size: 3rem;
}

.afp-4x {
  font-size: 4rem;
}

.afp-5x {
  font-size: 5rem;
}

.afp {
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "antilles" !important;
  line-height: 1;
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.afp-data-templates:before {
  content: "\e900";
}

.afp-lrs:before {
  content: "\e901";
}

.afp-site-improve:before {
  content: "\e902";
}

.afp-archive:before {
  content: "\e903";
}

.afp-coordinates:before {
  content: "\e904";
}

.afp-git:before {
  content: "\e905";
}

.afp-payment:before {
  content: "\e906";
}

.afp-phone:before {
  content: "\e907";
}

.afp-redirect:before {
  content: "\e908";
}

.afp-has-comments:before {
  content: "\e909";
}

.afp-no-comments:before {
  content: "\e90a";
}

.afp-click-dimensions:before {
  content: "\e90b";
}

.afp-bulk-upload:before {
  content: "\e90c";
}

.afp-tags:before {
  content: "\e90d";
}

.afp-upload:before {
  content: "\e90e";
}

.afp-alert:before {
  content: "\e90f";
}

.afp-audit:before {
  content: "\e910";
}

.afp-ban:before {
  content: "\e911";
}

.afp-blog:before {
  content: "\e912";
}

.afp-bullhorn:before {
  content: "\e913";
}

.afp-calculator:before {
  content: "\e914";
}

.afp-camera:before {
  content: "\e915";
}

.afp-cart:before {
  content: "\e916";
}

.afp-chat:before {
  content: "\e917";
}

.afp-checkmark:before {
  content: "\e918";
}

.afp-clock:before {
  content: "\e919";
}

.afp-close:before {
  content: "\e91a";
}

.afp-cloud:before {
  content: "\e91b";
}

.afp-company-directory:before {
  content: "\e91c";
}

.afp-dashboard:before {
  content: "\e91d";
}

.afp-down-arrow:before {
  content: "\e91e";
}

.afp-download:before {
  content: "\e91f";
}

.afp-employment:before {
  content: "\e920";
}

.afp-envelope:before {
  content: "\e921";
}

.afp-events:before {
  content: "\e922";
}

.afp-exclamation-point:before {
  content: "\e923";
}

.afp-expand:before {
  content: "\e924";
}

.afp-eye:before {
  content: "\e925";
}

.afp-eye-covered:before {
  content: "\e926";
}

.afp-fast-backward:before {
  content: "\e927";
}

.afp-fast-backward-beginning:before {
  content: "\e928";
}

.afp-fast-forward:before {
  content: "\e929";
}

.afp-fast-forward-end:before {
  content: "\e92a";
}

.afp-forms:before {
  content: "\e92b";
}

.afp-forward:before {
  content: "\e92c";
}

.afp-heart:before {
  content: "\e92d";
}

.afp-image:before {
  content: "\e92e";
}

.afp-information:before {
  content: "\e92f";
}

.afp-left-arrow:before {
  content: "\e930";
}

.afp-lightbulb:before {
  content: "\e931";
}

.afp-link:before {
  content: "\e932";
}

.afp-lists:before {
  content: "\e933";
}

.afp-locations:before {
  content: "\e934";
}

.afp-lock:before {
  content: "\e935";
}

.afp-menu:before {
  content: "\e936";
}

.afp-message:before {
  content: "\e937";
}

.afp-minimize:before {
  content: "\e938";
}

.afp-mute:before {
  content: "\e939";
}

.afp-news:before {
  content: "\e93a";
}

.afp-new-window:before {
  content: "\e93b";
}

.afp-pages:before {
  content: "\e93c";
}

.afp-paintbrush:before {
  content: "\e93d";
}

.afp-paperclip:before {
  content: "\e93e";
}

.afp-pause:before {
  content: "\e93f";
}

.afp-percent:before {
  content: "\e940";
}

.afp-play-button:before {
  content: "\e941";
}

.afp-question-mark:before {
  content: "\e942";
}

.afp-refresh:before {
  content: "\e943";
}

.afp-resources:before {
  content: "\e944";
}

.afp-rewind:before {
  content: "\e945";
}

.afp-right-arrow:before {
  content: "\e946";
}

.afp-rss:before {
  content: "\e947";
}

.afp-search:before {
  content: "\e948";
}

.afp-seo:before {
  content: "\e949";
}

.afp-settings:before {
  content: "\e94a";
}

.afp-star:before {
  content: "\e94b";
}

.afp-star-circle:before {
  content: "\e94c";
}

.afp-stop:before {
  content: "\e94d";
}

.afp-training:before {
  content: "\e94e";
}

.afp-trash:before {
  content: "\e94f";
}

.afp-unlock:before {
  content: "\e950";
}

.afp-up-arrow:before {
  content: "\e951";
}

.afp-users:before {
  content: "\e952";
}

.afp-vertical-dots:before {
  content: "\e953";
}

.afp-view-site:before {
  content: "\e954";
}

.afp-volume:before {
  content: "\e955";
}

.afp-volume-down:before {
  content: "\e956";
}

.afp-wand:before {
  content: "\e957";
}

.afp-wifi:before {
  content: "\e958";
}

.afp-zoom-in:before {
  content: "\e959";
}

.afp-zoom-out:before {
  content: "\e95a";
}

.afp-minus:before {
  content: "\e95b";
}

.afp-plus:before {
  content: "\e95c";
}

.afp-sdk-developer:before {
  content: "\e95d";
}

.afp-helpdesk:before {
  content: "\e95e";
}

.afp-save-and-return:before {
  content: "\e95f";
}

.afp-icon-font:before {
  content: "\e960";
}

.afp-clipboard:before {
  content: "\e961";
}

.afp-linked-in:before {
  content: "\e962";
}

.afp-google-plus:before {
  content: "\e963";
}

.afp-instagram:before {
  content: "\e964";
}

.afp-youtube:before {
  content: "\e965";
}

.afp-twitter:before {
  content: "\e966";
}

.afp-pinterest:before {
  content: "\e967";
}

.afp-facebook:before {
  content: "\e968";
}

.afp-integrations:before {
  content: "\e969";
}

.afp-pencil:before {
  content: "\e96a";
}

.afp-drag-to-sort:before {
  content: "\e96b";
}

.afp-version:before {
  content: "\e96c";
}

.afp-quick-edit:before {
  content: "\e96d";
}

.afp-save:before {
  content: "\e96e";
}

.afp-floppy:before {
  content: "\e96f";
}

.afp-control-center:before {
  content: "\e970";
}

.afp-edit:before {
  content: "\e971";
}

.afp-rates-solid-background:before {
  content: "\e972";
}

.afp-rates:before {
  content: "\e973";
}

.afp-icons:before {
  content: "\e974";
}

.afp-modules:before {
  content: "\e975";
}

.afp-photo-gallery:before {
  content: "\e976";
}

.afp-duplicate:before {
  content: "\e977";
}